<template>
  <div class="page">
    <Tables
      :tableName="tableName"
      :tableData="tableData"
      :totalPage="totalPage"
      :isLoading="isLoading"
      :showRefresh="true"
      @getList="getList"
    >

      <vxe-table-column
        slot="table-item"
        title="内容"
        align="center"
      >
        <template v-slot="{ row }">
          <el-image
            v-if="row.suffix === 'image'"
            style="width: 100px; height: 48px"
            :src="row.url"
            :preview-src-list="viewImgList(row)"
            fit="contain"
          >
          </el-image>
          <el-link
            v-else
            type="success"
            :href="row.url"
            style="text-decoration:underline"
          >
            {{row.name}}
          </el-link>
        </template>
      </vxe-table-column>
      <vxe-table-column
        slot="table-item"
        title="类型"
        align="center"
      >
        <template v-slot="{ row }">
          <span v-if="row.suffix === 'image'">图片</span>
          <span v-else>附件</span>
        </template>
      </vxe-table-column>
      <vxe-table-column
        slot="table-item"
        title="大小"
        align="center"
      >
        <template v-slot="{ row }">
          <span>{{(row.size/1024/1024).toFixed(2)}} MB</span>
        </template>
      </vxe-table-column>
      <vxe-table-column
        slot="table-item"
        field="create_user"
        title="上传用户"
        align="center"
      />
      <vxe-table-column
        slot="table-item"
        field="create_time"
        title="上传时间"
        align="center"
      />

    </Tables>
  </div>
</template>

<script>
import Tables from '@/components/tables'
import Upload from '@/components/upload'

export default {
  name: 'system-attachment-list',
  components: {
    Upload,
    Tables
  },
  data () {
    return {
      tableName: '附件管理',
      tableData: [],
      totalPage: 0,
      isLoading: false
    }
  },
  computed: {
    viewImgList () {
      return function (row) {
        return [row.url]
      }
    },
    hasAccess () {
      return function (val) {
        return this.$store.state.user.userInfo.route.includes(val)
      }
    }
  },
  methods: {
    getList (currentPage, pageSize) {
      if (this.hasAccess('system.attachment/index')) {
        const params = {
          token: this.$store.state.user.token,
          page: currentPage,
          size: pageSize,
          pj_id: ''
        }
        this.isLoading = true
        this.$api.System.GetFileList(params).then(res => {
          this.totalPage = res.total
          this.tableData = res.data
          this.isLoading = false
        }).catch(err => {
          this.isLoading = false
        })
      } else {
        this.$message.warning('当前用户暂无权限')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
